<template>
  <div>
    <h1 class="text-primary title-patternOne">
      Você já possui um cadastro na Educacross. Digite sua senha para vincular o
      acesso da criança a este e-mail.
    </h1>
    <b-form @submit.prevent="activateAccess()" class="form-withPadding">
      <b-form-group>
        <b-form-input
          id="email"
          type="email"
          placeholder="E-mail*"
          size="lg"
          required
          v-model="formActivateAccess.email"
          @keypress="preventWhiteSpace($event)"
        ></b-form-input>
      </b-form-group>
      <b-form-row>
        <b-col cols="12">
          <b-form-group class="passwordInput-container">
            <b-form-input
              id="password"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Senha*"
              size="lg"
              required
              v-model="formActivateAccess.password"
              class="input-withIconRight"
            ></b-form-input>
            <div>
              <b-icon
                icon="eye-slash-fill"
                class="color-gray1 icon-password"
                @click="showOrHidePassword()"
                v-if="showPassword"
              ></b-icon>
              <b-icon
                icon="eye-fill"
                class="color-gray1 icon-password"
                @click="showOrHidePassword()"
                v-else
              ></b-icon>
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-group class="text-right">
        <router-link to="forgot-password" class="text-patternOne">
          Esqueci minha senha
        </router-link>
      </b-form-group>
      <error-message :error="feedbackGeneralError" />
      <b-form-group>
        <b-button
          type="submit"
          variant="secondary"
          size="lg"
          :disabled="loadingButton"
          ><b-spinner large v-if="loadingButton"></b-spinner
          ><span v-else>Avançar</span></b-button
        >
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
export default {
  data: () => ({
    loadingButton: false,
    formActivateAccess: {
      email: '',
      password: '',
    },
    showPassword: false,
    feedbackGeneralError: {
      status: 0,
      message: [],
      errorDictionary: [],
      data: {},
    },
  }),
  methods: {
    activateAccess() {
      this.loadingButton = true;
      this.$onboardingService
        .login(this.formActivateAccess)
        .then((response) => {
          localStorage.setItem('account_token', response.data.token);
          this.$router.push('/user-link-conditions');
        })
        .catch((error) => {
          if (error.status === 403 && error.data.accountId) {
            this.$router.push('/validate-email');
            this.$store.state.accountId = error.data.accountId;
            localStorage.setItem('account_id', error.data.accountId);
            localStorage.removeItem('totalCodeTimeValidateEmail');
          } else {
            this.feedbackGeneralError = error;
            this.loadingButton = false;
          }
        });
    },
    showOrHidePassword() {
      this.showPassword = !this.showPassword;
    },
    preventWhiteSpace(event) {
      let key = event.keyCode;
      if (key === 32) event.preventDefault();
    },
  },
  created() {
    if (this.$store.state.emailVerified)
      this.formActivateAccess.email = this.$store.state.emailVerified;

    localStorage.removeItem('account_token');
  },
};
</script>

<style lang="scss" scoped>
h1.text-primary.title-patternOne {
  margin-bottom: 1.25rem;
}

form {
  .btn {
    margin-top: 2.75rem;
  }
}

a.router-link-active {
  text-transform: uppercase;
  text-decoration: underline;
}

@media (max-width: 768px) {
  a.router-link-active {
    text-transform: none;
  }

  form {
  .btn {
    margin-top: 1.75rem;
  }
}
}
</style>